import { Controller } from 'stimulus'
import Swiper, { Autoplay, Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Autoplay])

export default class extends Controller {
  static targets = ['prev', 'next']

  connect() {
    new Swiper(this.element, {
      autoplay: {
        delay: 10000,
      },
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1536: {
          slidesPerView: 4,
        },
      },
    })
  }
}
