import { Controller } from 'stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Pagination])

export default class extends Controller {
  static targets = ['prev', 'next', 'pagination']

  connect() {
    new Swiper(this.element, {
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      loop: true,
      noSwiping: true,
      slidesPerView: 1,
      spaceBetween: 0,
    })
  }
}
