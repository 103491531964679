import { Controller } from 'stimulus'
import Swiper, { Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use(Navigation)

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
      centeredSlides: true,
      loop: true,
      slidesPerView: 1.25,
      spaceBetween: 32,
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        640: {
          slidesPerView: 2.25,
        },
        1024: {
          slidesPerView: 3.25,
        },
        1536: {
          slidesPerView: 4.25,
        },
      },
    })
  }
}
