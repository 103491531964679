import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'

import 'alpinejs'
import 'typeface-manrope'
import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  window.scrollTo(0, 0)

  new SweetScroll()
})

window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}
