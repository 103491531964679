import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'deposit', 'rate', 'term', 'mortgage', 'interest', 'repayment', 'list']

  connect() {
    this.calculate()
  }

  calculate() {
    const price = this.priceTarget.value || 250_000
    const deposit = this.depositTarget.value || 25_000
    const rate = this.rateTarget.value || 3
    const term = this.termTarget.value || 35

    this.price = price

    this.mortgageCost(price, deposit)
    this.repaymentCost(rate, term)
  }

  mortgageCost(price, deposit) {
    const value = price - deposit

    this.mortgage = value.toFixed(2)
    this.mortgageTarget.innerText = value ? this.money(value) : '---'
  }

  repaymentCost(rate, term) {
    const p = this.mortgage
    const i = rate / 100 / 12
    const n = term * 12

    const cost = (p * i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1)

    this.repayment = cost.toFixed(2)
    this.repaymentTarget.innerText = cost && cost !== Infinity ? this.money(cost) : '---'
  }

  money(value) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumSignificantDigits: 5,
    }).format(value)
  }
}
