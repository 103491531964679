import { Controller } from 'stimulus'
import MarkerClusterer from '@google/markerclustererplus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    let map
    let bounds = new google.maps.LatLngBounds()
    let markers = []
    let startPosition = {
      lat: parseFloat(this.properties[0].latitude),
      lng: parseFloat(this.properties[0].longitude),
    }

    map = new google.maps.Map(this.containerTarget, {
      center: startPosition,
      zoom: 5,
    })

    for (let property of this.properties) {
      let marker
      let position = { lat: parseFloat(property.latitude), lng: parseFloat(property.longitude) }
      let contentString = this.generateContent(property)
      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      })

      marker = new google.maps.Marker({
        position: position,
        map: map,
        title: property.vebra_ref,
      })

      marker.addListener('click', function () {
        infowindow.open(map, marker)
      })

      markers.push(marker)

      bounds.extend(position)
      marker.setMap(map)
    }

    map.fitBounds(bounds)
    map.setZoom(5)
    new MarkerClusterer(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
    })
  }

  generateContent(property) {
    return `<div class="w-64">
        <img src="${property.image}" class="w-full h-56 rounded-lg object-cover" />
        <h1 class="text-xl font-semibold text-gold mt-2">
          ${property.address} - ${property.price}
        </h1>
        <a
          href="/properties/${property.slug}"
          class="button w-full border-gold text-gold hover:bg-gold hover:text-white mt-4"
        >
          View property
        </a>
      </div>`
  }

  get properties() {
    return JSON.parse(this.data.get('properties'))
  }
}
