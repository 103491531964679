import { Controller } from 'stimulus'

import { config, calculator } from '../services/stamp-duty'

export default class extends Controller {
  static targets = ['price', 'type', 'cost']

  connect() {
    this.calculate()
  }

  calculate() {
    const { propertyTypes, countries, buyerTypes } = config
    const type = this.typeTarget.value === 'true' ? buyerTypes.FIRST_TIME : buyerTypes.MOVING_HOUSE
    const price = this.priceTarget.value
    const result = calculator(price, propertyTypes.RESIDENTIAL, countries.ENGLAND, type)

    if (price > 425_000 && price <= 625_000 && this.typeTarget.value === 'true') {
      this.costTarget.innerText = this.money(result.tax - 5000)
    } else {
      this.costTarget.innerText = this.money(result.tax)
    }
  }

  money(value) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      maximumSignificantDigits: 5,
    }).format(value)
  }
}
