import { Controller } from 'stimulus'
import Swiper, { Autoplay, Pagination } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Pagination])

export default class extends Controller {
  static targets = ['prev', 'next', 'container', 'pagination']

  connect() {
    new Swiper(this.containerTarget, {
      autoplay: {
        delay: 10000,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 32,
      pagination: {
        el: this.paginationTarget,
        type: 'fraction',
      },
    })
  }
}
