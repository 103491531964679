import { Controller } from 'stimulus'

import SweetScroll from 'sweet-scroll'

export default class extends Controller {
  static targets = ['content']

  scrollTo() {
    const scroller = new SweetScroll()

    scroller.toElement(this.contentTarget)
  }
}
