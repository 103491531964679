import { Controller } from 'stimulus'
import Swiper, { Navigation } from 'swiper'

import 'swiper/swiper-bundle.css'

Swiper.use(Navigation)

export default class extends Controller {
  static targets = ['container', 'prev', 'next']

  connect() {
    new Swiper(this.containerTarget, {
      autoplay: 6000,
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      navigation: {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      },
    })
  }
}
